import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useResults from "./useResults";
import SuccessImage from "../assets/success.png";
import DiamondImage from "../assets/diamond.svg";
import StudentImage from "../assets/Student.png";
import AiRobotImage from "../assets/AiRobot.png";
import { useState } from "react";
const MODAL_TYPE = {
  AI_REPORT: 2,
  OCCUPATION_PROFILE: 1,
};
const modalInfo = {
  1: {
    title:'Awesome!',
    image:DiamondImage,
    text:`You'll be redirected to an external resource with all the details on this career outlook. Make sure to come back to Career Scoops to keep exploring and discovering more opportunities!`
  },
  2: {
    title:'Ready!',
    image:SuccessImage,
    text:`Your AI Insights Report will open in a new tab! Be sure to dive into it to get your personalized insights and learn more about your career potential.`
  },
  3: {
    title:'Let’s do this!',
    image:StudentImage,
    text:`Want to request a mentorship session with a professional in this career field? Click 'Okay' and we'll get the process started!`,
    text2:'Note: Availability depends on your Career Scoops plan.'
  },
  4: {
    title:'Let’s go!',
    image:AiRobotImage,
    text:`Launching your personalized AI Career Buddy! Chat live for instant career guidance and advice.`
  },
}
const useCareer = () => {
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.profileReducer);
  const [isConfirmModal, setIsConfirmModal] = useState(null)
  const {
    chosenItem,
    handlescore,
    handleBook,
    handleBack,
    closeSuccessModal,
  } = useResults();

  const { state } = useLocation();
  function handleBookValidation(id, val) {
    if (profile.pricing_plan || profile.affiliate_code) {
      handleBook(id);
    } else {
      navigate("/pricingplan");
    }
  }
  const handleClose = (val=null)=>[
    setIsConfirmModal(val)
  ]
  const handleConfirm=()=>{
    if(isConfirmModal === MODAL_TYPE.AI_REPORT){
      window.open(profile?.ai_report, '_blank');
    }else if(isConfirmModal === MODAL_TYPE.OCCUPATION_PROFILE){
      window.open(`https://www.careeronestop.org/Toolkit/Careers/Occupations/occupation-profile.aspx?keyword=${state?.unique_title?.title}&onetcode=${state?.unique_title?.id}`, '_blank');
    }
    setIsConfirmModal(null)
  }
  const navigateBack = () => {
    navigate(-1);
  };
  return {
    navigateBack,
    state,
    profile,
    chosenItem,
    modalInfo,
    isConfirmModal,
    closeSuccessModal,
    handleClose,
    handleConfirm,
    handlescore,
    handleBack,
    handleBook,
    handleBookValidation,
  };
};

export default useCareer;
