import React, { useEffect } from "react";
import { AiThinking, AiResult } from "./Components";
import "./ScenarioResult.scss";
import useResults from "../../../../hooks/useResults";
import Error from "../../../../assets/error.svg";
import { Success } from "../../../../modals";
import { useNavigate } from "react-router-dom";

const ScenarioResult = () => {
  const navigate = useNavigate();
  const {
    results,
    isModalOpened,
    chosenItem,
    isSuccessModalOpened,
    selectedItems,
    isErrorModalOpened,
    handleModalOpen,
    closeModal,
    handlescore,
    handleBook,
    closeSuccessModal,
  } = useResults(true);

  useEffect(() => {
    console.log("results", results);
  }, []);

  return (
    <div className="ScenarioResult">
      {results?.length >= 10 ? (
        <AiResult
          data={results}
          isModalOpened={isModalOpened}
          handleModalOpen={handleModalOpen}
          closeModal={closeModal}
          chosenItem={chosenItem}
          handlescore={handlescore}
          handleBook={handleBook}
          isSuccessModalOpened={isSuccessModalOpened}
          closeSuccessModal={closeSuccessModal}
          selectedItems={selectedItems}
        />
      ) : (
        <AiThinking />
      )}

      <Success
        image={Error}
        title=""
        text={isErrorModalOpened}
        isOpened={isErrorModalOpened}
        close={() => {
          navigate("/pricingplan");
        }}
      />
    </div>
  );
};

export default ScenarioResult;
