import { useState } from "react";
import { useSelector } from "react-redux";
import { authUser } from "../redux/selectors/authSelectors";
import useWindowWidth from "./useWindowWidth";
import { useNavigate } from "react-router-dom";
const useProfileInfo = () => {
  const navigate = useNavigate()
  const { profile } = useSelector((state) => state.profileReducer);
  const user = useSelector(authUser);
  const { role } = user || {};
  const [isProfilePicModalOpened, setIsProfilePicOpened] = useState(false);
  const [imageFile, SetImageFile] = useState(null);
  const [image, setIMage] = useState(user?.image);
  const { width, isMobile } = useWindowWidth();
  const openPrfilePicModal = () => {
    if(isMobile){
      navigate("/profile/changePhoto")
    }else{
      setIsProfilePicOpened(true);
    }
  };
  const closeProfilePicModal = () => {
    setIsProfilePicOpened(false);
  };

  const getImageFile = (fileObj) => {
    SetImageFile(fileObj);
  };

  const getImage = (url) => {
    setIMage(url);
  };
  return {
    profile,
    isProfilePicModalOpened,
    image,
    role,
    imageFile,
    width,
    openPrfilePicModal,
    closeProfilePicModal,
    getImageFile,
    getImage,
  };
};

export default useProfileInfo;
