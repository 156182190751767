import React from "react";
import SuccessImage from "../../assets/learnMore.png";
import closeIcon from "../../assets/Close.svg";
import "./MoreRecommendations.scss";
import classNames from "classnames";
const MoreRecommendations = ({ title, text, isOpened, onClose, aiReportUrl, handleRecommend,careerOutlookVal }) => {
  if (!isOpened) {
    return <></>
  }
  return <div className="More" onClick={onClose}>
    <div className="More__content" onClick={(e) => e.stopPropagation()}>
      <div className="More__content__heading__imgWrapper">
      <button>
            <img
            src={closeIcon}
            alt="closeIcon"
            className="More__content__imgClose"
            onClick={onClose}
          />
            </button>
        <img
          src={SuccessImage}
          alt="More"
          className="More__content__heading__imgWrapper__img"
        />
      </div>
      <div className="More__content__container">
        <div className="More__content__heading">
          {title ? <p className="More__content__heading__title">{title}</p> : null}
          <p className="More__content__heading__text">{text}</p>
        </div>
        <div className="More__content__btns">
          <button className={classNames("More__content__btn", { More__content__btn_disable: !careerOutlookVal })} disabled={!careerOutlookVal} onClick={()=>handleRecommend(1)}>
            <p className="More__content__btn__ok">Career Outlook</p>
          </button>
          <button className={classNames("More__content__btn", { More__content__btn_disable: !aiReportUrl })} disabled={!aiReportUrl} onClick={()=>handleRecommend(2)}>
            <p className="More__content__btn__ok">AI Insights Report</p>
          </button>
          <button className="More__content__btn" onClick={()=>handleRecommend(3)}>
            <p className="More__content__btn__ok">Mentorship Session</p>
          </button>
          <button className="More__content__btn More__content__btn_disable" disabled onClick={()=>handleRecommend(4)}>
            <p className="More__content__btn__ok">AI Career Buddy</p>
          </button>
        </div>
      </div>
    </div>
  </div>
};

export default MoreRecommendations;
