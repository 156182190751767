import React from "react";
import "./CancelModal.scss";

const CancelModal = ({
  image,
  title,
  text,
  text2,
  leftBtnText,
  rightBtnText,
  isOpened,
  close,
  removeChanges
}) => {
  return isOpened ? (
    <div className="CancelModal-cont" onClick={close}>
      <div className="CancelModal-cont__content" onClick={(e)=>(e.stopPropagation())}>
        <div className="CancelModal-cont__content__imgWrapper">
          <img
            src={image}
            alt="goodIMage"
            className="CancelModal-cont__content__imgWrapper__img"
          />
        </div>
        <div className="CancelModal-cont__content__textCont">
          <p className="CancelModal-cont__content__textCont__title">{title}</p>
          <p className="CancelModal-cont__content__textCont__text">{text}</p>
          {text2 ? <p className="CancelModal-cont__content__textCont__text">{text2}</p>:null}
        </div>
        <div className="CancelModal-cont__content__btnContainer">
          <button className="CancelModal-cont__content__btnContainer__btn1"  onClick={close}>
            <p className="CancelModal-cont__content__btnContainer__btn1__text">
              {leftBtnText}
            </p>
          </button>
          <button className="CancelModal-cont__content__btnContainer__btn2" onClick={removeChanges}>
            <p className="CancelModal-cont__content__btnContainer__btn2__text">
              {rightBtnText}
            </p>
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default CancelModal;
