import { LucideSquareM } from "lucide-react";
import * as actionTypes from "../actionTypes";

export const initGetQuotes = (data) => ({
  type: actionTypes.INIT_GET_QUOTES,
  data
});

export const getQuotes = (data) => ({
  type: actionTypes.GET_QUOTES,
  data
})

export const getQuotesSuccess = (payload) => ({
  type: actionTypes.GET_QUOTES_SUCCESS,
  payload,
});

export const getQuotesError = (error) => ({
  type: actionTypes.GET_QUOTES_ERROR,
  error,
});