import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { setPrfoile, setPrfoileSuccess, setPrfoileError } from "../actions";

export function* setProfileSaga( {payload} ) {
  yield put(setPrfoile())
  try {
    const response = yield call(routes.setProfile, payload);
    yield put(setPrfoileSuccess(response));
  } catch (error) {
    yield put(setPrfoileError(error));
  }
}