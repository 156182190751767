import React, { useRef, useState } from "react";
import classNames from "classnames";
import "./ProfilePicModal.scss";

const ProfilePicModal = ({
  title,
  image,
  icon,
  leftBtnText,
  rightBtnText,
  isOpened,
  close,
  getImageFile,
  getImage,
}) => {
  const fileInputRef = useRef(null);
  const [choosenImage, setChoosenImage] = useState("");
  const [imageFile,setImageFile] = useState(null)
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const url = window.URL.createObjectURL(file);
    setChoosenImage(url);
    setImageFile(file);
  };
  return isOpened ? (
    <div className="ProfilePicModal-cont" onClick={() => {
        setChoosenImage(image)
        setImageFile(null)
        close()
    }}>
      <div
        className="ProfilePicModal-cont__content"
        onClick={(e) => e.stopPropagation()}
      >
        <p className="ProfilePicModal-cont__content__title">{title}</p>
        <div className="ProfilePicModal-cont__content__imgWrapper">
          <img
            src={choosenImage ? choosenImage : image}
            alt="userIMage"
            className={classNames({
              mainImg: choosenImage && !choosenImage.includes("Human"),
            })}
          />
          <img
            src={icon}
            alt="ICON"
            className="ProfilePicModal-cont__content__imgWrapper__icon"
            onClick={handleIconClick}
          />
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileInputChange}
          />
        </div>
        <div className="ProfilePicModal-cont__content__btnContainer">
          <button
            className="ProfilePicModal-cont__content__btnContainer__btn1"
            onClick={() => {
                setChoosenImage(image)
                setImageFile(imageFile)
                close()
            }}
          >
            <p className="ProfilePicModal-cont__content__btnContainer__btn1__leftBtnText">
              {leftBtnText}
            </p>
          </button>
          <button
            className="ProfilePicModal-cont__content__btnContainer__btn2"
            onClick={() => {
              getImage(choosenImage);
              getImageFile(imageFile)
              close();
            }}
          >
            <p className="ProfilePicModal-cont__content__btnContainer__btn2__rightBtnText">
              {rightBtnText}
            </p>
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default ProfilePicModal;
