import { takeEvery } from "redux-saga/effects";
import * as initActions from "./actionTypes";
import { signInSaga, signUpSaga, tagsSaga, mentorSaga, scenariosSaga, submitScenarioSaga, getResultsSaga, setResultsSaga, getPricingPlanSaga, setPricingPlanSaga, getProfileSaga, setProfileSaga, getRecommendationsSaga,getFeedBackSaga,getSessionsSaga, getIndustriesSaga,getQuotesSaga } from "./sagas";

export function* rootSaga() {
  yield takeEvery(initActions.INIT_SIGNIN, signInSaga);
  yield takeEvery(initActions.INIT_SIGNUP, signUpSaga);
  yield takeEvery(initActions.INIT_TAGS, tagsSaga);
  yield takeEvery(initActions.INIT_MENTORS, mentorSaga)
  yield takeEvery(initActions.INIT_SCENARIOS, scenariosSaga)
  yield takeEvery(initActions.INIT_SUBMIT_SCENARIOS, submitScenarioSaga)
  yield takeEvery(initActions.INIT_RESULTS, getResultsSaga)
  yield takeEvery(initActions.INIT_SET_RESULTS, setResultsSaga)
  yield takeEvery(initActions.INIT_GET_PRICING_PLAN, getPricingPlanSaga)
  yield takeEvery(initActions.INIT_SET_PRICING_PLAN, setPricingPlanSaga)
  yield takeEvery(initActions.INIT_GET_PROFILE, getProfileSaga)
  yield takeEvery(initActions.INIT_SET_PROFILE, setProfileSaga)
  yield takeEvery(initActions.INIT_GET_RECOMMENDATIONS, getRecommendationsSaga)
  yield takeEvery (initActions.INIT_GET_FEEDBACKS, getFeedBackSaga)
  yield takeEvery (initActions.INIT_GET_SESSIONS, getSessionsSaga)
  yield takeEvery (initActions.INIT_INDUSTRY, getIndustriesSaga)
  yield takeEvery(initActions.INIT_GET_QUOTES, getQuotesSaga);

}
